import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {ArticleService} from '../../_service/article.service';
import {ArticleWrapper} from '../../_entity/article-wrapper';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleCategory} from '../../_entity/article-category';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {ArticleCategoryResponse} from "../../_response/article-category-response";
import {EnvironmentModel} from "../../_globals/environment-model";
import {ApiService} from "../../_service/api.service";
import {TransferState, makeStateKey} from '@angular/platform-browser';
import {isPlatformBrowser} from "@angular/common";
import {CustomLogger} from "../../_globals/custom-logger";


@Component({
    selector: 'app-article-category',
    templateUrl: './article-category.component.html',
    styleUrls: ['./article-category.component.css']
})
export class ArticleCategoryComponent implements OnInit {

    public config;
    public category: ArticleCategory;
    public articles = new Array<ArticleWrapper>();
    public type: string;

    private page = 0;
    private pageSize = 10;
    private categorySURL: string;
    public reachedLastArticle = false;

    public loading = false;
    public show404Page = false;


    constructor(private api: ApiService, private backend: ArticleService, private route: ActivatedRoute, private meta: Meta, private title: Title,
                private logger: CustomLogger, private router: Router, private environment: EnvironmentModel,
                @Inject(PLATFORM_ID) private platformId: Object,
                private readonly transferState: TransferState) {}

    ngOnInit() {
        this.categorySURL = this.route.snapshot.params.surl;
        this.logger.access();
        this.loadCategory();
    }

    private loadCategory(): void {
        const storeKey = makeStateKey<string>('articleCategory');
        if(isPlatformBrowser(this.platformId)) {
            this.category = this.transferState.get(storeKey, <any>[]);
            this.loadArticleList(this.page, this.pageSize, this.categorySURL);
        } else {
            this.backend.getCategory(this.categorySURL).subscribe(response => {
                const messageCode = response.message.code;
                if (messageCode === 404) {
                    this.show404Page = true;
                } else {
                    this.category = response.category;
                    this.transferState.set(storeKey, this.category);
                    this.buildMetaTagsCategory();
                }
            });
        }
    }

    private loadArticleList(page: number, pageSize: number, categorySURL: string) {
        this.logger.info('loadArticleList ### categorySURL: '+ categorySURL+' ### pageSize: '+pageSize+' ### page: '+page);
        this.loading = true;
        this.api.getArticleList(page * pageSize, pageSize, categorySURL)
            .subscribe(response => {
                this.category = response.category;
                response.articles.forEach(item => {
                    this.articles.push(item);
                });
                if (response.articles.length === 0) {
                    this.reachedLastArticle = true;
                }
            },
            // The 2nd callback handles errors.
            (err) => {
                // this.logger.error(err);
                this.loading = false;
            },
            // The 3rd callback handles the "complete" event.
            () => {
                this.loading = false;
            });
    }

    public isLoaded() {
        return this.category !== undefined;
    }

    public loadMore(event: any) {
        this.loadNextPage();
    }

    public loadNextPage() {
        this.page++;
        this.loadArticleList(this.page, this.pageSize, this.categorySURL);
    }

    private buildMetaTagsCategory() {
        this.title.setTitle(this.category.name + ' - ' + this.environment.siteName);
        this.meta.updateTag(this.createTag('title',
            this.category.name + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createTag('description', this.category.name));
        this.meta.updateTag(this.createTag('keywords',
            this.category.name + ' ' + this.environment.meta.keywords));
        this.meta.updateTag(this.createOGTag('og:title',
            this.category.name + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createOGTag('og:description', this.category.name));
        this.meta.updateTag(this.createOGTag('og:url', this.environment.webUrl +"/"+ this.category.name));
        this.meta.updateTag(this.createOGTag('og:type', 'article'));

    }

    private createTag(nameValue: string, contentValue: string): MetaDefinition {
        return {
            name: nameValue,
            content: contentValue
        };
    }

    private createOGTag(name: string, value: string): MetaDefinition {
        return {
            property: name,
            content: value
        };
    }

}
