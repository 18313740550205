import {ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ArticleWrapper} from '../../_entity/article-wrapper';
import {ArticleCategory} from '../../_entity/article-category';
import {ArticleListResponse} from '../../_response/article-list-response';
import {isPlatformBrowser} from "@angular/common";
import {TransferState} from "@angular/platform-browser";

@Component({
    selector: 'app-article-category-block-item',
    templateUrl: './article-category-block-item.component.html',
    styleUrls: ['./article-category-block-item.component.css']
})

export class ArticleCategoryBlockItemComponent implements OnInit {

    @Input()
    public type: string;

    // @Input() blockId: string;

    @Input()
    public block: ArticleListResponse;

    public articleWrappers: ArticleWrapper[];
    public category: ArticleCategory;

    public mainArticleWrapper: ArticleWrapper;

    constructor() {}

    ngOnInit() {
        this.articleWrappers = this.block.articles;
        this.mainArticleWrapper = this.block.articles.shift();
        this.category = this.block.category;
    }

    public isNarrow(): boolean {
        return this.category && this.type === 'narrow';
    }
    public isWide(): boolean {
        return this.category && this.type === 'wide';
    }

}
