import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../_components/home/home.component';
import {ArticleComponent} from "../_components/article/article.component";
import {ArticleCategoryComponent} from "../_components/article-category/article-category.component";
import {InfoPageComponent} from "../_components/infopage/info-page.component";
import {HealthComponent} from "../_components/health/health.component";
import {TagComponent} from "../_components/tag/tag.component";
import {BareLayoutComponent} from "../_components/bare-layout/bare-layout.component";
import {MainLayoutComponent} from "../_components/main-layout/main-layout.component";

const routes: Routes = [
    {
        path: 'info',
        component: BareLayoutComponent,
        children: [
            { path: 'health', component: HealthComponent },
        ]
    },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            { path: '', component: HomeComponent },
            { path: ':surl', component: ArticleComponent },
            { path: ':year/:month/:day/:surl', component: ArticleComponent },
            { path: 'category/:surl', component: ArticleCategoryComponent },
            { path: 'info/:surl', component: InfoPageComponent },
            { path: 'healthchecker/readiness', component: HealthComponent },
            { path: 'tags/:tag', component: TagComponent },
            // { path: 'pageNotFound404', pathMatch: 'full', component: PageNotFound404Component },
            { path: '**', redirectTo: '/' }
        ]
    },
];

// const routes: Routes = [
//   { path: '', component: HomeComponent },
//   { path: ':surl', component: ArticleComponent },
//   { path: ':year/:month/:day/:surl', component: ArticleComponent },
//   { path: 'category/:surl', component: ArticleCategoryComponent },
//   { path: 'info/:surl', component: InfoPageComponent },
//   { path: 'healthchecker/readiness', component: HealthComponent },
//   { path: 'tags/:tag', component: TagComponent },
//     // { path: 'pageNotFound404', pathMatch: 'full', component: PageNotFound404Component },
//   { path: '**', redirectTo: '/' }
// ];



@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        relativeLinkResolution: 'legacy',
        preloadingStrategy: PreloadAllModules,
    })],
    exports: [RouterModule],
    providers: [
    ]
})
export class AppRoutingModule {
}
