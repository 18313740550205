import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ArticleService} from '../../_service/article.service';
import {ArticleWrapper} from '../../_entity/article-wrapper';
import {ActivatedRoute} from '@angular/router';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {ArticleCategoryResponse} from "../../_response/article-category-response";
import {EnvironmentModel} from "../../_globals/environment-model";
import {isPlatformBrowser} from "@angular/common";
import {ApiService} from "../../_service/api.service";
import {CustomLogger} from "../../_globals/custom-logger";

@Component({
    selector: 'app-article-category',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {

    public articles = new Array<ArticleWrapper>();
    public tag: string;
    private page = 0;
    private pageSize = 10;
    public reachedLastArticle = false;
    public loading = false;

    constructor(private api: ApiService,
                private route: ActivatedRoute,
                private logger: CustomLogger,
                private meta: Meta, private title: Title,
                public readonly environment: EnvironmentModel,
                @Inject(PLATFORM_ID) private platformId: Object) {
    }

    ngOnInit() {
        this.tag = this.route.snapshot.params.tag;
        this.logger.access();
        this.loadTag();
        this.buildMetaTagsCategory();
    }

    private loadTag() {
        if(isPlatformBrowser(this.platformId)) {
            this.loadArticleList(this.page, this.pageSize, this.tag);
        }
    }

    private loadArticleList(page: number, pageSize: number, tag: string) {
        this.logger.info('loadArticleList ### tag: '+ tag+' ### pageSize: '+pageSize+' ### page: '+page);
        this.loading = true;
        this.api.getArticleListByTag(page * pageSize, pageSize, tag)
            .subscribe(response => {
                response.articles.forEach(item => {
                    this.articles.push(item);
                });
                if (response.articles.length === 0) {
                    this.reachedLastArticle = true;
                }
            },
            // The 2nd callback handles errors.
            (err) => {
                // this.logger.error(err);
                this.loading = false;
            },
            // The 3rd callback handles the "complete" event.
            () => {
                this.loading = false;
            });
    }

    public loadMore(event): void {
        this.loadNextPage();
    }

    public loadNextPage() {
        this.page++;
        this.loadArticleList(this.page, this.pageSize, this.tag);
    }

    private buildMetaTagsCategory() {
        this.title.setTitle(this.tag + ' - ' + this.environment.siteName);
        this.meta.updateTag(this.createTag('title',
            this.tag + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createTag('description', this.tag));
        this.meta.updateTag(this.createTag('keywords',
            this.tag + ' ' + this.environment.meta.keywords));
        this.meta.updateTag(this.createOGTag('og:title',
            this.tag + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createOGTag('og:description', this.tag));
        this.meta.updateTag(this.createOGTag('og:url', this.environment.webUrl +"/"+ this.tag));
        this.meta.updateTag(this.createOGTag('og:type', 'article'));

    }

    private createTag(nameValue: string, contentValue: string): MetaDefinition {
        return {
            name: nameValue,
            content: contentValue
        };
    }

    private createOGTag(name: string, value: string): MetaDefinition {
        return {
            property: name,
            content: value
        };
    }

}
