import {Component, OnInit} from '@angular/core';
import {Meta, MetaDefinition, Title} from "@angular/platform-browser";
import {EnvironmentModel} from "../../_globals/environment-model";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent {

    public currentYear = new Date().getFullYear();

    constructor(private meta: Meta, private title: Title, public readonly environment: EnvironmentModel) {
        this.buildMetaTags();
    }

    private buildMetaTags() {
        this.meta.addTag({
            'http-equiv': 'Content-Type',
            content: 'text/html; charset=utf-8'
        });

        this.meta.addTag({
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge'
        });

        this.meta.addTag({
            charset: 'utf-8'
        });

        this.title.setTitle(this.environment.siteName);
        this.meta.addTag(this.createTag('copyright', this.currentYear + ' &copy; ' + this.environment.siteName));
        this.meta.addTag(this.createTag('language', 'hu'));
        this.meta.addTag(this.createTag('robots', 'index, follow'));
        this.meta.addTag(this.createTag('viewport', 'width=device-width, initial-scale=1'));
        this.meta.addTag(this.createTag('author', this.environment.siteName));
        this.meta.addTag(this.createTag('revisitAfter', '1 days'));
        this.meta.addTag(this.createTag('rating', 'general'));
        this.meta.addTag(this.createOGTag('fb:app_id', this.environment.facebookAppID));
        this.meta.addTag(this.createOGTag('og:locale', this.environment.meta.locale));
        this.meta.addTag(this.createOGTag('og:site_name', this.environment.siteName));

        this.meta.addTag(this.createTag('title', this.environment.siteName));
        this.meta.addTag(this.createTag('description', this.environment.meta.description));
        this.meta.addTag(this.createTag('keywords', this.environment.meta.keywords));
        this.meta.addTag(this.createOGTag('og:title', this.environment.siteName));
        this.meta.addTag(this.createOGTag('og:description', this.environment.slogan));
        this.meta.addTag(this.createOGTag('og:url', this.environment.webUrl));
        this.meta.addTag(this.createOGTag('og:type', 'article'));
        this.meta.addTag(this.createOGTag('og:image', this.environment.mainImage));

    }

    public createTag(nameValue: string, contentValue: string): MetaDefinition {
        return {
            name: nameValue,
            content: contentValue
        };
    }

    public createOGTag(name: string, value: string): MetaDefinition {
        return {
            property: name,
            content: value
        };
    }

}

