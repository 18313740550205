
<div *ngIf="type == 'thin'" [ngClass]="{'ad-bottom': bottom}" class="tr-ad ad-wide text-center dummy-ad">
    <ng-adsense
        [adClient]="'ca-pub-3940208088608087'"
        [adSlot]="6660574447"
        [height]="90"
        [display]="'block'"
        [layout]="'in-article'"
        [adFormat]="'fluid'"
        [fullWidthResponsive]="true"
    ></ng-adsense>
    <div class="ad-warning text-center">hirdetés</div>
</div>

<div *ngIf="type == 'sidebar'" class="tr-section tr-ad ad-sidebar">
    <ng-adsense
        [adClient]="'ca-pub-3940208088608087'"
        [adSlot]="4018099230"
        [display]="'block'"
        [adFormat]="'auto'"
    ></ng-adsense>
    <div class="ad-warning ad-warning-sidebar text-center">hirdetés</div>
</div>

<div *ngIf="type == 'fluid'" [ngClass]="{'ad-bottom': bottom}" class="tr-ad ad-wide text-center dummy-ad">
    <ng-adsense
        [adClient]="'ca-pub-3940208088608087'"
        [adSlot]="6660574447"
        [display]="'block'"
        [layout]="'in-article'"
        [adFormat]="'fluid'"
        [fullWidthResponsive]="true"
    ></ng-adsense>
    <div class="ad-warning text-center">hirdetés</div>
</div>

