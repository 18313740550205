<app-home-slider></app-home-slider>
<div class="row tr-content">
    <app-article-category-blocks [showLeft]="true" [showRight]="true"></app-article-category-blocks>
    <app-sidebar></app-sidebar>
</div>






