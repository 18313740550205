<app-topbar></app-topbar>
<div class="main-wrapper tr-page-top">
    <app-menu></app-menu>
    <app-advertise [type]="'thin'"></app-advertise>
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</div>
<app-footer [currentYearFooter]="currentYear"></app-footer>

