import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-banner-adsense',
  templateUrl: './banner-adsense.component.html',
  styleUrls: ['./banner-adsense.component.css'],
})
export class BannerAdsenseComponent {

    @Input() bottom: boolean;

    @Input() type: string;

    constructor() {
    }

}

