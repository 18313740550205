import {Inject, Optional, PLATFORM_ID} from "@angular/core";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";


export class EnvironmentModel {
    public adsense: { adClient: string; show: boolean };
    public apiUrl: string;
    public backendUrl: string;
    public facebookAppID: string;
    public mainImage: string;
    public meta: { description: string; keywords: string; locale: string; revisitAfter: string; rating: string; robots: string };
    public production: boolean;
    public siteName: string;
    public slogan: string;
    public staticContentUrl: string;
    public webUrl: string;

    public namespace: string;
    public podName: string;

    constructor(@Optional() @Inject('ENV') private envFromServer: any,
                @Inject(PLATFORM_ID) private platformId: Object,
                private readonly transferState: TransferState) {

        let env = this.loadEnv(this.envFromServer);
        this.adsense = {adClient: env.ADSENSE_CLIENT_ID, show: env.ADSENSE_SHOW == 'true'};
        this.apiUrl = env.API_URL;
        this.backendUrl = env.BACKEND_URL;
        this.facebookAppID = env.FB_APP_ID;
        this.mainImage = env.MAIN_IMAGE;
        this.meta = {
            description: env.META_DESCRIPTION,
            keywords: env.META_KEYWORDS,
            locale: env.META_LOCALE,
            revisitAfter: env.META_REVISIT_AFTER,
            rating: env.META_RATING,
            robots: env.META_ROBOTS
        };
        this.production = env.PRODUCTION;
        this.siteName = env.SITE_NAME;
        this.slogan = env.SLOGAN;
        this.staticContentUrl = env.STATIC_CONTENT_URL;
        this.webUrl = env.WEB_URL;
        this.podName = env.HOSTNAME;
        this.namespace = env.NAMESPACE;

    }

    loadEnv(envFromServer: any): any {
        const envStoreKey = makeStateKey<string>('env');
        if(isPlatformBrowser(this.platformId)) //get message from transferState if browser side
        {
            return this.transferState.get(envStoreKey, <any> []);
        }
        else //server side: get provided message and store in in transfer state
        {
            this.transferState.set(envStoreKey, envFromServer);
            return envFromServer;
        }
    }


    // loadApiUrl(apiUrlFromServer: string): string {
    //     const apiUrlStoreKey = makeStateKey<string>('apiUrl');
    //     if(isPlatformBrowser(this.platformId)) //get message from transferState if browser side
    //     {
    //         return this.transferState.get(apiUrlStoreKey, <any> []);
    //     }
    //     else //server side: get provided message and store in in transfer state
    //     {
    //         this.transferState.set(apiUrlStoreKey, apiUrlFromServer);
    //         return apiUrlFromServer;
    //     }
    // }

}
