<div *ngIf="isWide()" class="tr-section wide-list">
    <div class="tr-post">
        <div class="section-title title-before">
            <h1><a href="/category/{{category.surl}}?ua_src=blockwide">{{category.name}}</a></h1>
        </div>

        <app-article-item [articleWrapper]="mainArticleWrapper"
                          [showLead]="true" [showTags]="false" imageSize="medium"></app-article-item>

    </div>

    <div class="medium-post-content row">
        <div *ngFor="let articleWrapper of articleWrappers" class="col-md-6 left-post">
            <div class="tr-post">
                <app-article-item [articleWrapper]="articleWrapper"
                                  [showLead]="true" [showTags]="false"
                                  imageSize="small"></app-article-item>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isNarrow()" class="tr-section">
    <div class="tr-post">
        <div class="section-title title-before">
            <h1><a href="/category/{{category.surl}}?ua_src=blocknrw">{{category.name}}</a></h1>
        </div>
        <app-article-item [articleWrapper]="mainArticleWrapper"
                            [showLead]="true" [showTags]="false" imageSize="small"></app-article-item>
</div>
    <ul class="small-post-list">
        <li *ngFor="let articleWrapper of articleWrappers">
            <app-article-item [articleWrapper]="articleWrapper"
                              [showLead]="true" [showTags]="false"
                              imageSize="small"></app-article-item>
        </li>
    </ul>
</div>
