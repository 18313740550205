<div class="tr-menu menu-responsive">
    <nav class="navbar navbar-default">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>

        <div class="collapse navbar-left navbar-collapse" id="navbar-collapse">
            <ul class="nav navbar-nav">
                <li *ngFor="let menuItem of menu" [ngClass]="{'dropdown': menuItem.aggregator}">
                    <a *ngIf="!menuItem.aggregator" href="/category/{{menuItem.surl}}?ua_src=menu">{{menuItem.name}}</a>
                    <a *ngIf="menuItem.aggregator">
                        {{menuItem.name}} <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </a>
                    <ul *ngIf="menuItem.aggregator" class="sub-menu">
                        <li *ngFor="let child of menuItem.children">
                            <a href="/category/{{child.surl}}?ua_src=menu">
                                {{child.name}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <ul class="feed pull-right">
            <li>
                <a href="https://www.facebook.com/CreepyShake" target="_blank">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/creepyshake666" target="_blank">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
            </li>
            <li>
                <a href="https://twitter.com/Creepy_Shake" target="_blank">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
            </li>
        </ul>
    </nav>
</div>
