import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ArticleListResponse} from '../../../../_response/article-list-response';
import {ArticleService} from '../../../../_service/article.service';
import {EnvironmentModel} from "../../../../_globals/environment-model";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {makeStateKey, TransferState} from "@angular/platform-browser";

@Component({
    selector: 'app-home-slider',
    templateUrl: './home-slider.component.html',
    styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {

    public config;
    public mainArticles: ArticleListResponse;

    constructor(private backend: ArticleService,
                public readonly environment: EnvironmentModel,
                @Inject(PLATFORM_ID) private platformId: any,
                private readonly transferState: TransferState) {}

    ngOnInit() {
        this.loadSlider();
    }

    loadSlider(): void {
        const envStoreKey = makeStateKey<string>('homeSlider');
        if(isPlatformBrowser(this.platformId)) {
            this.mainArticles = this.transferState.get(envStoreKey, <any> []);
        } else {
            this.backend.getArticleMain().subscribe(response => {
                this.mainArticles = response;
                this.transferState.set(envStoreKey, this.mainArticles);
            });
        }
    }

}
