import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ArticleListResponse} from '../_response/article-list-response';
import {EnvironmentModel} from "../_globals/environment-model";
import {CustomLogger} from "../_globals/custom-logger";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    private readonly GET_ARTICLE_LIST_API;
    private readonly GET_ARTICLES_BY_TAG;

    constructor(private http: HttpClient, private environment: EnvironmentModel, private logger: CustomLogger) {
        const apiUrl = this.environment.apiUrl;
        this.GET_ARTICLE_LIST_API = `${apiUrl}/article/list`;
        this.GET_ARTICLES_BY_TAG = `${apiUrl}/article/tag`;
    }


    getArticleList(first: number, pageSize: number, categorySURL: string): Observable<ArticleListResponse> {
        let params = new HttpParams();
        params = params.append('first', String(first));
        params = params.append('page_size', String(pageSize));
        params = params.append('category_surl', categorySURL);
        return this.http.get<ArticleListResponse>(this.GET_ARTICLE_LIST_API, {params});
    }


    getArticleListByTag(first: number, pageSize: number, tag: string): Observable<ArticleListResponse> {
        let params = new HttpParams();
        params = params.append('first', String(first));
        params = params.append('page_size', String(pageSize));
        params = params.append('tag', tag);
        return this.http.get<ArticleListResponse>(this.GET_ARTICLES_BY_TAG, {params});
    }

}
