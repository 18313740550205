<app-page-not-found404 *ngIf="show404Page"></app-page-not-found404>
<div *ngIf="!show404Page && page !== undefined" class="row">
  <div class="col-sm-9 tr-sticky">
    <div class="tr-content theiaStickySidebar">
      <div class="tr-section">
        <div class="tr-post">

          <div class="post-content">
            <h2 class="entry-title" [innerHTML]="page.title"></h2>
          </div>
        </div>
        <app-advertise [type]="'thin'"></app-advertise>
        <div class="tr-details article-content">
          <p [innerHTML]="page.content"></p>
        </div>
        <app-advertise [type]="'thin'"></app-advertise>
      </div>
    </div>
  </div>
  <app-sidebar></app-sidebar>
</div>
