<app-page-not-found404 *ngIf="show404Page"></app-page-not-found404>

<div *ngIf="!show404Page" class="row tr-content">
    <div class="col-sm-5 col-md-6 tr-sticky">
        <div class="theiaStickySidebar">
            <div *ngIf="isLoaded()" class="tr-section wide-list">
                <div class="tr-post">
                    <div class="section-title title-before">
                        <h1 [innerHTML]="category.name"></h1>
                    </div>
                </div>
            </div>
            <!-- lazy loading -->
<!--            <div *ngIf="isLoaded()" class="boundary" [attr.data-page]="0" [attr.data-surl]="category.surl" [attr.data-loaded]="0"></div>-->
            <!-- end of lazy loading -->

            <div *ngFor="let articleWrapper of articles" class="tr-section wide-list" >
                <div class="tr-post">
                        <app-article-item [articleWrapper]="articleWrapper"
                                          [showLead]="true" [showTags]="false"
                                          imageSize="medium"></app-article-item>
                    </div>
            </div>


            <div *ngIf="!reachedLastArticle" style="position: relative; z-index: 1000;" class="push-to-load-wrapper text-center" (click)="loadMore($event)">
                További cikkek...
            </div>

            <div *ngIf="loading" id="loader-wrapper" class="loader-wrapper">
                <div id="loader" class="loader text-center">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                    <span class="sr-only">Betöltés...</span>
                </div>
            </div>


        </div>
    </div>

    <app-article-category-blocks [showLeft]="false" [showRight]="true"></app-article-category-blocks>
    <app-sidebar></app-sidebar>

</div>
