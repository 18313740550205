import {Component} from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './bare-layout.component.html',
  styleUrls: ['./bare-layout.component.css'],
})
export class BareLayoutComponent {

    constructor() {
    }

}

