<div class="tr-topbar clearfix margin-bottom-0">
    <div class="row">
        <div class="col-sm-3">
            <a class="navbar-brand brand-text" href="/">
            <img class="img-responsive" src="assets/img/creepyshake_logo_brand.png"
                 alt="{{altTitle}}"
                 title="{{altTitle}}">
            </a>
        </div>
        <div class="col-sm-9">
            <div class="topbar-left">
                <!--
                <div class="breaking-news">
                    <span># Hírfolyam</span>
                    <div id="ticker">
                        <ul>
                            <li><a href="#">aaa aaaaaa aaaaaa aaaaaa aaaaaa aaaaaa aaaaaa aaaaaa aaaaaa aaa</a></li>
                            <li><a href="#">bbbbbb bbbbbb bbbbbb bbbbbb bbbbbb bbbbbb bbbbbb bbbbbb bbbbbb </a></li>
                            <li><a href="#">cccccc cccccc cccccc cccccc cccccc cccccc cccccc cccccc cccccc </a></li>
                            <li><a href="#">dddddd dd dddddd dd dddddd dd dddddd dd dddddd dd dddddd dd dddddd dd </a></li>

                        </ul>
                    </div>
                </div>-->
            </div>
            <div class="topbar-right">
<!--                <div class="searchNlogin">-->
<!--                    <ul>-->
<!--                        <li class="search-icon"><i class="fa fa-search"></i></li>-->
<!--                    </ul>-->
<!--                    <div class="search">-->
<!--                        <form method="post" action="/kereses" autocomplete="off">-->
<!--                            <input type="text" class="search-form" name="searchword" id="searchword" placeholder="Keresés" />-->
<!--                        </form>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</div>


