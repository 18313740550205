<div *ngIf="articleWrapper">
    <a href="{{articleWrapper.article.surl}}?ua_src=ai">
        <div class="entry-header">
            <div class="entry-thumbnail">

                    <img class="img-responsive"
                         src="{{environment.staticContentUrl}}/uploads/image/{{articleWrapper.article.image[imageSize]}}"
                         alt="{{articleWrapper.article.title}} - {{articleWrapper.category.name}}" />

            </div>
        </div>
    </a>
<div class="post-content">
    <!--<div class="author">
        <a href="#"><img class="img-responsive img-circle" src="images/others/author3.png" alt="Image"></a>
    </div>-->
<!--    <div class="entry-meta">-->
<!--        <ul>-->
<!--            <li>{{articleWrapper.author.displayedName}}</li>-->
<!--            <li>{{articleWrapper.article.publishedAt}}</li>-->
<!--            <li><a href="/category/{{articleWrapper.category.surl}}?ua_src=ai">{{articleWrapper.category.name}}</a></li>-->


<!--            <li *ngIf="showTags && articleWrapper.article.tags != null && articleWrapper.article.tags.length > 0" class="cs-tags">-->
<!--                <a *ngFor="let tag of articleWrapper.article.tags" href="/tags/{{tag}}?ua_src=ai" class="margin-side-10 tag">#{{tag}}</a>-->
<!--            </li>-->
<!--        </ul>-->
<!--    </div>-->
    <h2 class="entry-title">
        <a href="{{articleWrapper.article.surl}}?ua_src=ai">{{articleWrapper.article.title}}</a>
    </h2>



    <p *ngIf="showLead">{{articleWrapper.article.lead}}</p>
<!--    <div class="read-more">-->
<!--        <div class="continue-reading pull-left">-->
<!--            <a href="{{articleWrapper.article.surl}}">Tovább <i class="fa fa-angle-right"></i></a>-->
<!--        </div>-->
<!--    </div>-->

</div>
</div>
