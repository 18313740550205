import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {BannerService} from '../../_service/banner.service';
import {Banner} from '../../_entity/banner';
import {EnvironmentModel} from "../../_globals/environment-model";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {CustomLogger} from "../../_globals/custom-logger";

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

    public banners: Banner[];

    constructor(private backend: BannerService,
                public readonly environment: EnvironmentModel,
                private logger: CustomLogger,
                @Inject(PLATFORM_ID) private platformId: any,
                private readonly transferState: TransferState) {

        if(isPlatformServer(platformId)) {
            this.backend.getBanners().subscribe(response => {
                this.banners = response.banners;
            });
        }
    }

    ngOnInit() {
        this.loadBanners();
    }

    private loadBanners() {
        const envStoreKey = makeStateKey<string>("banners");
        if (isPlatformBrowser(this.platformId)) {
            const response = this.transferState.get(envStoreKey, <any>[]);
            this.banners = response.banners;
        } else {
            this.backend.getBanners().subscribe(response => {
                this.banners = response.banners;
                this.transferState.set(envStoreKey, response);
            });
        }
    }

}
