import * as winston from "winston";
import {format, Logger, transports} from "winston";
import {Inject, Optional, PLATFORM_ID} from "@angular/core";
import {Router} from "@angular/router";
import {EnvironmentModel} from "./environment-model";
import {isPlatformBrowser} from "@angular/common";
const { combine, timestamp } = format;
const DailyRotateFile = require("winston-daily-rotate-file");

export class CustomLogger {

    private winstonLogger: Logger;
    private logTypeServer = 'server';
    private logTypeAccess = 'access';
    private logTypeError = 'error';
    private readonly headers:any;
    private readonly podName:string;
    private readonly namespace:string;
    private readonly method:string;


    constructor(@Optional() @Inject('HEADERS') headers: string,
                @Optional() @Inject('METHOD') method: string,
                @Inject(PLATFORM_ID) private platformId: Object,
                private environmentModel: EnvironmentModel,
                private router: Router) {

        this.headers = headers;
        this.podName = environmentModel.podName;
        this.namespace = environmentModel.namespace;
        this.method = method;


        this.winstonLogger = winston.createLogger({
            level: 'info',
            format: combine(
                timestamp(),
                winston.format.json()
            ),
            transports: []
        });

        if(isPlatformBrowser(this.platformId)) {
            this.winstonLogger.add(new transports.Console());
            this.winstonLogger.transports.forEach((t) => (t.silent = true));
        } else {
            const transport = new (DailyRotateFile)({
                filename: '/logs/creepyshake-web/' + this.namespace + '-' + this.podName + '-%DATE%_server.log',
                datePattern: 'YYYY-MM-DD',
                zippedArchive: true,
                maxSize: '50m',
                maxFiles: '7d'
            });
            this.winstonLogger.add(transport);
        }
    }

    public log(level:string, type:string, message:string, url:string) {
        const fullUrlObject = new URL(this.environmentModel.webUrl+url)
        this.winstonLogger.log(level, {
            type: type,
            method: this.method,
            current_url: fullUrlObject.pathname,
            ua_src: fullUrlObject.searchParams.get("ua_src"),
            headers: this.headers,
            namespace: this.namespace,
            pod_name: this.podName,
            message: message,
        });
    }

    public info(message:string) {
        this.log('info', this.logTypeServer, message, this.router.url);
    }

    public access() {
        this.log('info', this.logTypeAccess, "access", this.router.url);
    }

    public error(message:string) {
        this.log('error', this.logTypeError, message, this.router.url);
    }

    public warning(message:string) {
        this.log('warning', this.logTypeServer, message, this.router.url);
    }

}

