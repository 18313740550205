import {Component, Input, OnInit} from '@angular/core';
import {ArticleWrapper} from '../../_entity/article-wrapper';
import {EnvironmentModel} from "../../_globals/environment-model";

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.css'],
})
export class ArticleItemComponent implements OnInit {

  @Input()
  public articleWrapper: ArticleWrapper;

  @Input()
  public showLead: boolean;

  @Input()
  public showTags: boolean;

  @Input()
  public imageSize: string;

  readonly environment: EnvironmentModel;

  constructor(private environmentModel: EnvironmentModel) {
      this.environment = environmentModel;
  }

  ngOnInit() {
  }

}
