import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {EnvironmentModel} from "../../_globals/environment-model";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.css']
})
export class AdvertiseComponent implements  OnInit {

    @Input()
    public type: string;

    @Input()
    public bottom = false;

    public showAd: boolean;

    constructor(private environment: EnvironmentModel,
                @Inject(PLATFORM_ID) private platformId: Object,
                private readonly transferState: TransferState) {
    }

    ngOnInit() {
        this.showAd = this.environment.adsense.show;
        this.loadAdsenseData();
    }

    private loadAdsenseData() {
        const storeKey = makeStateKey<string>('show_ad');
        if(isPlatformBrowser(this.platformId)) {
            this.showAd = this.transferState.get(storeKey, <any>[]);
        } else {
            this.transferState.set(storeKey, this.environment.adsense.show);
            this.showAd = this.environment.adsense.show;
        }
    }

}
