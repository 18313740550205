import {ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ArticleService} from '../../_service/article.service';
import {ArticleBlocksResponse} from '../../_response/article-blocks-response';
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {makeStateKey, TransferState} from "@angular/platform-browser";

@Component({
    selector: 'app-article-category-blocks',
    templateUrl: './article-category-blocks.component.html',
    styleUrls: ['./article-category-blocks.component.css']
})
export class ArticleCategoryBlocksComponent implements OnInit {

    @Input()
    public showLeft = false;

    @Input()
    public showRight = false;

    public articleBlocks: ArticleBlocksResponse;

    constructor(private backend: ArticleService,
                @Inject(PLATFORM_ID) private platformId: any,
                private readonly transferState: TransferState) {}

    ngOnInit() {
        this.articleBlocks = this.loadArticleBlocks();
    }

    loadArticleBlocks(): ArticleBlocksResponse {
        const envStoreKey = makeStateKey<string>('articleBlocks');
        if(isPlatformBrowser(this.platformId)) {
            return this.transferState.get(envStoreKey, <any> []);
        } else {
            this.backend.getArticleBlocks().subscribe(response => {
                this.transferState.set(envStoreKey, response);
                return response;
            });
        }
    }

    public doShowLeft(): boolean {
        return this.showLeft && this.articleBlocks !== undefined;
    }

    public doShowRight(): boolean {
        return this.showRight && this.articleBlocks !== undefined;
    }

}
