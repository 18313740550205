<div class="error-page text-center">
    <div class="tr-section tr-section-padding">
        <div class="error-page-content">
            <div class="not-found">
                <h1>404</h1>
                <p>Tartalom nem található! Ez creepy...</p>
                <a href="/" class="btn btn-primary">Vissza a főoldalra</a>
            </div>
        </div>
    </div>
</div>
