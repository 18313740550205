import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {MenuResponse} from '../_response/menu-response';
import {EnvironmentModel} from "../_globals/environment-model";

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private GET_MENU_URL;

    constructor(private http: HttpClient, private environment: EnvironmentModel) {
        this.GET_MENU_URL = `${environment.backendUrl}/menu/get`;
    }

    getMenu(): Observable<MenuResponse> {
        return this.http.get<MenuResponse>(this.GET_MENU_URL);
    }

}
