<!-- LEFT SIDE -->
<div *ngIf="doShowLeft()" class="col-sm-5 col-md-6 tr-sticky">
    <div class="theiaStickySidebar">
        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_1" [block]="articleBlocks.blocks.bal_1" [type]="'wide'"></app-article-category-block-item>

        <app-advertise [type]="'fluid'"></app-advertise>

        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_2" [block]="articleBlocks.blocks.bal_2" [type]="'wide'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_3" [block]="articleBlocks.blocks.bal_3" [type]="'wide'"></app-article-category-block-item>


        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_4" [block]="articleBlocks.blocks.bal_4" [type]="'wide'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_5" [block]="articleBlocks.blocks.bal_5" [type]="'wide'"></app-article-category-block-item>


        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_6" [block]="articleBlocks.blocks.bal_6" [type]="'wide'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_7" [block]="articleBlocks.blocks.bal_7" [type]="'wide'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.bal_8" [block]="articleBlocks.blocks.bal_8" [type]="'wide'"></app-article-category-block-item>
        <app-advertise [type]="'fluid'"></app-advertise>

    </div>
</div>

<!-- RIGHT SIDE -->
<div *ngIf="doShowRight()" class="col-sm-4 col-md-3 medium-post-responsive tr-sticky">
    <div class="theiaStickySidebar">
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_1" [block]="articleBlocks.blocks.jobb_1" [type]="'narrow'"></app-article-category-block-item>

<!--        <app-advertise [type]="'fluid'"></app-advertise>-->

        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_2" [block]="articleBlocks.blocks.jobb_2" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_3" [block]="articleBlocks.blocks.jobb_3" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_4" [block]="articleBlocks.blocks.jobb_4" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_5" [block]="articleBlocks.blocks.jobb_5" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_6" [block]="articleBlocks.blocks.jobb_6" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_7" [block]="articleBlocks.blocks.jobb_7" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_8" [block]="articleBlocks.blocks.jobb_8" [type]="'narrow'"></app-article-category-block-item>
        <app-article-category-block-item *ngIf="articleBlocks.blocks.jobb_9" [block]="articleBlocks.blocks.jobb_9" [type]="'narrow'"></app-article-category-block-item>
<!--        <app-advertise [type]="'fluid'"></app-advertise>-->

    </div>
</div>
