import { Component } from '@angular/core';
import {CustomLogger} from "../../_globals/custom-logger";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent {

    constructor(private logger: CustomLogger) {
        this.logger.access();
    }

}
