<footer id="footer">
    <div class="footer-widgets">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <a class="brand-text" href="/">
                        <div class="logo">
                            <span>creepyshake.com</span>
                        </div>
                    </a>
              </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom text-center">
        <div class="container">
            <div class="footer-bottom-content">
                <p>
                    Az oldalon található tartalom a creepyshake.com tulajdona. Felhasználásuk engedélyhez kötött.
                </p>
                <p>
                    <a class="link" href="assets/NYILATKOZAT-A-CREEPYSHAKE-WEBOLDALAN-MEGJELENO-TARTALMAKKAL-KAPCSOLATBAN.pdf" style="text-decoration: underline;" target="_blank">
                        Jogi nyilatkozat
                    </a>
                    <a class="link" href="/info/impresszum" style="text-decoration: underline;">
                        Impresszum
                    </a>
                </p>
                <address>
                    <p>{{currentYearFooter}} &copy; <a href="/">CreepyShake.com</a>. Email: <a href="#">creepyshake@gmail.com</a></p>
                </address>
            </div>
        </div>
    </div>

    <app-advertise [type]="'thin'"></app-advertise>

</footer>
