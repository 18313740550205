import {BrowserModule, BrowserTransferStateModule, TransferState} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER, InjectionToken} from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './_components/home/home.component';
import { AppRoutingModule } from './_routing/app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppGlobals} from './_globals/app-globals';
import {FooterComponent} from './_components/footer/footer.component';
import {HomeSliderComponent} from './_components/home/_modules/home-slider/home-slider.component';
import {AdvertiseComponent} from './_components/advertise/advertise.component';
import {BannerComponent} from './_components/banner/banner.component';
import {TopbarComponent} from './_components/topbar/topbar.component';
import {MenuComponent} from './_components/menu/menu.component';
import {ArticleCategoryBlocksComponent} from './_components/article-category-blocks/article-category-blocks.component';
import {ArticleCategoryBlockItemComponent} from './_components/article-category-block-item/article-category-block-item.component';
import {SidebarComponent} from './_components/sidebar/sidebar.component';
import {PageNotFound404Component} from './_components/page-not-found404/page-not-found404.component';
import {ArticleItemComponent} from './_components/article-item/article-item.component';
import {ArticleCategoryComponent} from './_components/article-category/article-category.component';
import {ArticleComponent} from './_components/article/article.component';
import {NgEncodeURIPipeModule} from 'angular-pipes';
import {InfoPageComponent} from "./_components/infopage/info-page.component";
import {HealthComponent} from "./_components/health/health.component";
import {TagComponent} from "./_components/tag/tag.component";
import {MainLayoutComponent} from "./_components/main-layout/main-layout.component";
import {BareLayoutComponent} from "./_components/bare-layout/bare-layout.component";
import {BannerAdsenseComponent} from "./_components/banner-adsense/banner-adsense.component";
import {CustomLogger} from "./_globals/custom-logger";
import {EnvironmentModel} from "./_globals/environment-model";
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HomeComponent,
        AdvertiseComponent,
        BannerComponent,
        HomeSliderComponent,
        TopbarComponent,
        MenuComponent,
        ArticleCategoryBlocksComponent,
        ArticleCategoryBlockItemComponent,
        SidebarComponent,
        PageNotFound404Component,
        ArticleItemComponent,
        ArticleCategoryComponent,
        InfoPageComponent,
        ArticleComponent,
        HealthComponent,
        TagComponent,
        MainLayoutComponent,
        BareLayoutComponent,
        BannerAdsenseComponent
    ],
    providers: [
        AppGlobals,
        CustomLogger,
        EnvironmentModel
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        HttpClientModule,
        AppRoutingModule,
        NgEncodeURIPipeModule,
        BrowserTransferStateModule,
        AdsenseModule.forRoot()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
