<div *ngIf="mainArticles != null" class="tr-home-slider slider-style-custom">
    <div id="home-carousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li *ngFor="let articleWrapper of mainArticles.articles, let i = index" data-target="#home-carousel"
                [attr.data-slide-to]="i" [ngClass]="{'active': i == 0}">
                <span class="category">{{ articleWrapper.category.name}}</span>
                <span class="indicators-title">{{ articleWrapper.article.title }}</span>
            </li>
        </ol>
        <div class="carousel-inner" role="listbox">
            <div *ngFor="let articleWrapper of mainArticles.articles, let i = index" class="item"
                 [ngClass]="{'active': i == 0}">
                <div class="item-content">
                    <div class="item-image-content bg-image-wrapper" data-animation="animated slideInLeft"
                         [ngStyle]="{'background-image': 'url(' + environment.staticContentUrl + '/uploads/image/' + articleWrapper.article.image.large+')'}">
                        <div class="post-content" data-animation="animated fadeInDown">
                            <span class="category" data-animation="animated fadeInDown">
                                <a href="/category/{{ articleWrapper.category.surl }}">{{ articleWrapper.category.name }}</a>
                            </span>
                            <h2 class="entry-title" data-animation="animated fadeInDwn">
                                <a href="{{ articleWrapper.article.surl }}">
                                    {{ articleWrapper.article.title }}
                                </a>
                            </h2>
                            <div class="entry-meta" data-animation="animated fadeInDwn">
                                <ul>
                                    <li>{{ articleWrapper.author.displayedName }}</li>
<!--                                    <li>{{ articleWrapper.article.publishedAt }}</li>-->
<!--                                    <li>-->
<!--                                        <a href="/category/{{ articleWrapper.category.surl }}">{{ articleWrapper.category.name }}</a>-->
<!--                                    </li>-->
<!--                                    <li class="cs-tags" *ngIf="articleWrapper.article.tags != null && articleWrapper.article.tags.length > 0">-->
<!--                                        <a *ngFor="let tag of articleWrapper.article.tags"-->
<!--                                           href="/tags/{{ tag | encodeURI }}" class="margin-side-10 tag">#{{ tag }}</a>-->
<!--                                    </li>-->
                                </ul>
                            </div>
                            <p data-animation="animated fadeInUp">{{ articleWrapper.article.lead }}</p>
                            <div class="read-more" data-animation="animated fadeInUp">
                                <div class="continue-reading pull-left">
                                    <a href="{{ articleWrapper.article.surl }}">
                                        Tovább <i class="fa fa-angle-right"></i>
                                    </a>
                                </div>
                                <!--<div class="feed pull-right">
                                    <ul>
                                        <li>Megosztás</li>
                                        <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                        <li><a href="#"><i class="fa fa-rss" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
