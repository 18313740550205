import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ArticleResponse} from '../../_response/article-response';
import {ArticleWrapper} from '../../_entity/article-wrapper';
import {ResponseMessage} from '../../_entity/response-message';
import {ArticleService} from '../../_service/article.service';
import {DomSanitizer, makeStateKey, Meta, MetaDefinition, Title, TransferState} from '@angular/platform-browser';
import {EnvironmentModel} from "../../_globals/environment-model";
import {Observable} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {CustomLogger} from "../../_globals/custom-logger";


@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.css'],
})
export class ArticleComponent implements OnInit {

    public articleResponse: ArticleResponse;
    public articleWrapper: ArticleWrapper;
    public prevArticleWrapper: ArticleWrapper;
    public nextArticleWrapper: ArticleWrapper;
    private message: ResponseMessage;
    public show404Page = false;

    public proContraList: string[][];
    public overallPointsDegree: number = 0;
    public overallPointsRowspan = 1;

    constructor(private backend: ArticleService, private route: ActivatedRoute,
                private meta: Meta, private title: Title,
                private logger: CustomLogger,
                private sanitizer: DomSanitizer,
                public environment: EnvironmentModel,
                @Inject(PLATFORM_ID) private platformId: any,
                private readonly transferState: TransferState) {}

    ngOnInit() {
        this.logger.access();
        this.loadArticle();
    }

    private loadArticle() {
        const envStoreKey = makeStateKey<string>("articleResponse");
        if (isPlatformBrowser(this.platformId)) {
            const response = this.transferState.get(envStoreKey, <any>[]);
            this.processResponse(response);
        } else {
            this.getArticleCommon()
                .subscribe(response => {
                    this.transferState.set(envStoreKey, response);
                    this.processResponse(response);
                });
        }
    }

    private processResponse(response: ArticleResponse) {
        const messageCode = response.message.code;
        if (messageCode === 404) {
            this.show404Page = true;
        } else {
            this.show404Page = false;
            this.articleResponse = response;
            this.articleWrapper = this.articleResponse.articleWrapper;
            this.prevArticleWrapper = this.articleResponse.prevArticleWrapper;
            this.nextArticleWrapper = this.articleResponse.nextArticleWrapper;
            this.message = this.articleResponse.message;
            this.buildMetaTags();

            this.articleWrapper.article.htmlContent = this.articleWrapper.article.htmlContent.replace(/CREEPYSHAKE_MEDIA_HOST/g, this.environment.staticContentUrl);

            if(this.articleWrapper.article.showPoints) {
                this.proContraList = this.mergeProContraList(this.articleWrapper.article.positiveThings, this.articleWrapper.article.negativeThings);
                this.overallPointsDegree = this.articleWrapper.article.pointOverall;
                this.overallPointsRowspan = this.articleWrapper.article.positiveThings.length + 1;
            }
        }
    }

    get content() {
        let content =  this.sanitizer.bypassSecurityTrustHtml(this.articleWrapper.article.htmlContent);
        return content;
    }

    private getArticleCommon(): Observable<ArticleResponse> {
        // decide whether it is the old link or the new one
        if(this.route.snapshot.params.year !== undefined) {
            return this.backend.getArticleByFullData(this.route.snapshot.params.year, this.route.snapshot.params.month,
                this.route.snapshot.params.day, this.route.snapshot.params.surl);
        } else {
            return this.backend.getArticle(this.route.snapshot.params.surl);
        }
    }

    private buildMetaTags() {
        this.title.setTitle(this.articleWrapper.article.title + ' - ' + this.environment.siteName);
        this.meta.updateTag(this.createTag('title',
            this.articleWrapper.article.title + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createTag('description', this.articleWrapper.article.lead));
        this.meta.updateTag(this.createTag('keywords',
            this.articleWrapper.article.tags + ' ' + this.environment.meta.keywords));
        this.meta.updateTag(this.createOGTag('og:title',
            this.articleWrapper.article.title + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createOGTag('og:description', this.articleWrapper.article.lead));
        this.meta.updateTag(this.createOGTag('og:url', this.environment.webUrl + "/" + this.articleWrapper.article.surl));
        this.meta.updateTag(this.createOGTag('og:type', 'article'));
        this.meta.updateTag(this.createOGTag('og:locale', ''));
        this.meta.updateTag(this.createOGTag('og:image',
            this.environment.staticContentUrl + '/uploads/image/' + this.articleWrapper.article.image.large));

    }

    private createTag(nameValue: string, contentValue: string): MetaDefinition {
        return {
            name: nameValue,
            content: contentValue
        };
    }

    private createOGTag(name: string, value: string): MetaDefinition {
        return {
            property: name,
            content: value
        };
    }

    // private normalizeProContraList(article: Article) {
    //     let sizeOfNegativeThings = article.negativeThings.length;
    //     let sizeOfPositiveThings = article.positiveThings.length;
    //     let difference = Math.abs(sizeOfNegativeThings-sizeOfPositiveThings);
    //     if(sizeOfNegativeThings > sizeOfPositiveThings) {
    //         article.positiveThings = this.extendArray(article.positiveThings, difference);
    //     } else if(sizeOfPositiveThings > sizeOfNegativeThings) {
    //         article.negativeThings = this.extendArray(article.negativeThings, difference);
    //     }
    //
    // }
    //
    // private extendArray(arrayOfElements: string[], difference: number): string[] {
    //     for(let i = 0; i < difference; i++) {
    //         arrayOfElements.push("");
    //     }
    //     return arrayOfElements;
    // }

    private mergeProContraList(positiveThings: string[], negativeThings :string[]) {
        let result: string[][] = [];
        const size = positiveThings.length;

        for(let i = 0; i < size; i++) {
            let row: string[] = [];
            row.push(positiveThings[i]);
            row.push(negativeThings[i]);
            result.push(row);
        }
        return result;
    }

}

