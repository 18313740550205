import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ResponseMessage} from '../../_entity/response-message';
import {ArticleService} from '../../_service/article.service';
import {makeStateKey, Meta, MetaDefinition, Title, TransferState} from '@angular/platform-browser';
import {InfoPageResponse} from "../../_response/info-page-response";
import {InfoPage} from "../../_entity/info-page";
import {EnvironmentModel} from "../../_globals/environment-model";
import {isPlatformBrowser} from "@angular/common";
import {CustomLogger} from "../../_globals/custom-logger";

@Component({
  selector: 'app-infopage',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.css'],
})
export class InfoPageComponent implements OnInit {
    message: ResponseMessage;
    page: InfoPage;
    private surl: string;
    public show404Page = false;

    constructor(private backend: ArticleService,
                private route: ActivatedRoute,
                private meta: Meta, private title: Title,
                private logger: CustomLogger,
                @Inject(PLATFORM_ID) private platformId: Object,
                private readonly transferState: TransferState,
                public readonly environment: EnvironmentModel) {}

    ngOnInit() {
        this.logger.access();
        this.surl = this.route.snapshot.params.surl;
        this.loadInfoPage();
    }

    private loadInfoPage() {
        const storeKey = makeStateKey<string>('infopage');
        if(isPlatformBrowser(this.platformId)) {
            this.page = this.transferState.get(storeKey, <any>[]);
        } else {
            this.backend.getInfoPage(this.surl).subscribe(response => {
                const messageCode = response.message.code;
                if (messageCode === 404) {
                    this.show404Page = true;
                } else {
                    this.page = response.page;
                    this.transferState.set(storeKey, this.page);
                    this.buildMetaTags();
                }
            });
        }
    }

    private buildMetaTags() {
        this.title.setTitle(this.page.title + ' - ' + this.environment.siteName);
        this.meta.updateTag(this.createTag('title',
          this.page.title + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createTag('description', this.page.title));
        this.meta.updateTag(this.createTag('keywords',
          this.page.title + ' ' + this.environment.meta.keywords));
        this.meta.updateTag(this.createOGTag('og:title',
          this.page.title + ' - ' + this.environment.siteName));
        this.meta.updateTag(this.createOGTag('og:description', this.page.title));
        this.meta.updateTag(this.createOGTag('og:url', this.environment.webUrl + "/" +this.page.title));
        this.meta.updateTag(this.createOGTag('og:type', 'article'));
        // this.meta.updateTag(this.createOGTag('og:image',
        //     this.environment.staticContentUrl + '/uploads/image/' + this.articleWrapper.article.image.large));

    }

    private createTag(nameValue: string, contentValue: string): MetaDefinition {
        return {
            name: nameValue,
            content: contentValue
        };
    }

  private createOGTag(name: string, value: string): MetaDefinition {
        return {
            property: name,
            content: value
        };
    }

}

