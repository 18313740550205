import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ArticleListResponse} from '../_response/article-list-response';
import {ArticleResponse} from '../_response/article-response';
import {ArticleBlocksResponse} from '../_response/article-blocks-response';
import {ArticleCategoryResponse} from "../_response/article-category-response";
import {InfoPageResponse} from "../_response/info-page-response";
import {EnvironmentModel} from "../_globals/environment-model";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
    private readonly GET_ARTICLE_API;
    private readonly GET_ARTICLE_BY_FULL_API;
    private readonly GET_ARTICLE_MAIN_API;
    private readonly GET_ARTICLE_LIST_BLOCK_API;
    private readonly GET_ARTICLE_CATEGORY_API;
    private readonly GET_INFO_PAGE_API;
    private readonly GET_ARTICLES_BY_TAG;

    constructor(private http: HttpClient, private environment: EnvironmentModel) {
        const backendUrl = this.environment.backendUrl;
        this.GET_ARTICLE_API = `${backendUrl}/article/get`;
        this.GET_ARTICLE_BY_FULL_API = `${backendUrl}/article/getbyfull`;
        this.GET_ARTICLE_MAIN_API = `${backendUrl}/article/get/main`;
        this.GET_ARTICLE_LIST_BLOCK_API = `${backendUrl}/article/list/block`;
        this.GET_ARTICLE_CATEGORY_API = `${backendUrl}/category/get`;
        this.GET_INFO_PAGE_API = `${backendUrl}/info/get`;
    }



    getArticle(surl: string): Observable<ArticleResponse> {
        let params = new HttpParams();
        params = params.append('surl', surl);
        return this.http.get<ArticleResponse>(this.GET_ARTICLE_API, {params});
    }

    // The old method to get article. We don't need to use the date anymore. Use instead getArticle(surl: string)
    getArticleByFullData(year: string, month: string, day: string, surl: string): Observable<ArticleResponse> {
        let params = new HttpParams();
        params = params.append('year', year);
        params = params.append('month', month);
        params = params.append('day', day);
        params = params.append('surl', surl);
        return this.http.get<ArticleResponse>(this.GET_ARTICLE_BY_FULL_API, {params});
    }

    getArticleBlocks(): Observable<ArticleBlocksResponse> {
        return this.http.get<ArticleBlocksResponse>(this.GET_ARTICLE_LIST_BLOCK_API, {});
    }

    getArticleMain() {
        return this.http.get<ArticleListResponse>(this.GET_ARTICLE_MAIN_API);
    }

    getCategory(surl: string): Observable<ArticleCategoryResponse> {
        let params = new HttpParams();
        params = params.append('surl', surl);
        return this.http.get<ArticleCategoryResponse>(this.GET_ARTICLE_CATEGORY_API, {params});
    }

    getInfoPage(surl: string): Observable<InfoPageResponse> {
        let params = new HttpParams();
        params = params.append('surl', surl);
        return this.http.get<InfoPageResponse>(this.GET_INFO_PAGE_API, {params});
    }

}
