<app-page-not-found404 *ngIf="show404Page"></app-page-not-found404>
<div *ngIf="!show404Page && articleWrapper !== undefined" class="row">
    <div class="col-sm-9 tr-sticky">
        <div class="tr-content theiaStickySidebar">
            <div class="tr-section">
                <div class="tr-post">
                    <div class="entry-header">
                        <div class="entry-thumbnail">
                            <img class="responsive"
                                 src="{{environment.staticContentUrl}}/uploads/image/{{articleWrapper.article.image.large}}"
                                 alt="{{articleWrapper.article.title}}" title="{{articleWrapper.article.title}}"/>
                        </div>
                    </div>
                    <div class="post-content">
<!--                        <div class="author">-->
<!--                            <img *ngIf="articleWrapper.author.image" class="img-responsive img-circle"-->
<!--                                 src="{{config.staticContentUrl}}/uploads/image/{{articleWrapper.author.image}}_small.jpg"-->
<!--                                 alt="{{articleWrapper.author.displayedName}}" />-->
<!--                        </div>-->
                        <div class="entry-meta">
                            <ul>
                                <li>Szerző: {{articleWrapper.author.displayedName}}</li>
                                <li>
                                    <a href="/category/{{articleWrapper.category.surl}}?ua_src=ac">{{articleWrapper.category.name}}</a>
                                </li>
                                <li>{{articleWrapper.article.publishedAt}}</li>
                                <li *ngIf="articleWrapper.article.tags != null && articleWrapper.article.tags.length > 0" class="cs-tags">
                                    <a *ngFor="let tag of articleWrapper.article.tags" href="/tags/{{tag}}?ua_src=at"
                                       class="margin-side-10 tag">#{{tag}}</a>
                                </li>

                            </ul>
                        </div>
                        <h2 class="entry-title">
                            {{articleWrapper.article.title}}
                        </h2>
                        <div class="fb-like" attr.data-href="{{environment.webUrl}}/{{articleWrapper.article.surl}}" data-width="" data-layout="button_count" data-action="like" data-size="small" data-share="true"></div>
                    </div>
                </div>

                <app-advertise [type]="'thin'"></app-advertise>

                <div class="tr-details article-content">
                    <div [innerHTML]="content"></div>

                    <div class="clr"></div>

                    <div class="pro-contra-list-wrapper pro-contra-list-wrapper-list" *ngIf="articleWrapper.article.showPoints">

                        <div class="row tr-content ">
                            <div class="pro-list col-sm-12">
                                <ul>
                                    <li class="heading heading-pro">
                                        <img src="assets/img/review/review_plus.png" alt="" />
                                        <span>Pro</span>
                                    </li>
                                    <li *ngFor="let item of articleWrapper.article.positiveThings, let i = index" class="pro-contra-list-item">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                            <div class="contra-list col-sm-12">
                                <ul>
                                    <li class="heading heading-contra">
                                        <img src="assets/img/review/review_minus.png" alt="" />
                                        <span>Kontra</span>
                                    </li>
                                    <li *ngFor="let item of articleWrapper.article.negativeThings, let i = index" class="pro-contra-list-item">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                            <div class="contra-list col-sm-12 points-wrapper-div">
                                <div class="points-wrapper" *ngIf="articleWrapper.article.showPoints">
                                    <div class="points-overall-circle c100 p{{overallPointsDegree}}">
                                        <span class="overall-value">{{articleWrapper.article.pointOverall}}%</span>
                                        <div class="slice">
                                            <div class="bar"></div>
                                            <div class="fill"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pro-contra-list-wrapper pro-contra-list-wrapper-table" *ngIf="articleWrapper.article.showPoints">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td scope="col" class="heading heading-pro">
                                        <img src="assets/img/review/review_plus.png" alt="" />
                                        <span>Pro</span>
                                    </td>
                                    <td scope="col" class="heading heading-contra">
                                        <img src="assets/img/review/review_minus.png" alt="" />
                                        <span>Kontra</span>
                                    </td>
                                    <td [rowSpan]="overallPointsRowspan" class="points-wrapper-td heading vertical-center-container">
                                        <div style="min-width:120px; min-height: 120px;"></div>
                                        <div class="points-wrapper vertical-center horizontal-center">
                                            <div class="points-overall-circle c100 p{{overallPointsDegree}}">
                                                <span class="overall-value">{{articleWrapper.article.pointOverall}}%</span>
                                                <div class="slice">
                                                    <div class="bar"></div>
                                                    <div class="fill"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let item of proContraList; let index = index">
                                    <td>{{item[0]}}</td>
                                    <td>{{item[1]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

<!--                    <div class="points-wrapper" *ngIf="articleWrapper.article.showPoints">-->
<!--                        <table class="table">-->
<!--                            <tbody>-->
<!--                                <tr>-->
<!--                                    <td class="points-bar">-->
<!--                                        <div class="points-left" style="width: {{articleWrapper.article.pointBlood}}%;"></div>-->
<!--                                        <img src="assets/img/review/review_icon_axe.png" alt="" />-->
<!--                                        <span class="point-title">Véres</span>-->

<!--                                    </td>-->
<!--                                    <td class="points-value">{{articleWrapper.article.pointBlood}}</td>-->
<!--                                    <td class="points-overall" rowspan="4">-->

<!--                                        <div class="points-overall-circle c100 p{{overallPointsDegree}}">-->
<!--                                            <span class="overall-value">{{articleWrapper.article.pointOverall}}</span>-->
<!--                                            <div class="slice">-->
<!--                                                <div class="bar"></div>-->
<!--                                                <div class="fill"></div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td class="points-bar">-->
<!--                                        <div class="points-left" style="width: {{articleWrapper.article.pointTension}}%;"></div>-->
<!--                                        <img src="assets/img/review/review_icon_stressed.png" alt="" />-->
<!--                                        <span class="point-title">Feszültség</span>-->
<!--                                    </td>-->
<!--                                    <td class="points-value">{{articleWrapper.article.pointTension}}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td class="points-bar">-->
<!--                                        <div class="points-left" style="width: {{articleWrapper.article.pointFear}}%;"></div>-->
<!--                                        <img src="assets/img/review/review_icon_ghost.png" alt="" />-->
<!--                                        <span class="point-title">Félelem</span>-->
<!--                                    </td>-->
<!--                                    <td class="points-value">{{articleWrapper.article.pointFear}}</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td class="points-bar">-->
<!--                                        <div class="points-left" style="width: {{articleWrapper.article.pointStory}}%;"></div>-->
<!--                                        <img src="assets/img/review/review_icon_book.png" alt="" />-->
<!--                                        <span class="point-title">Történet</span>-->
<!--                                    </td>-->
<!--                                    <td class="points-value">{{articleWrapper.article.pointStory}}</td>-->
<!--                                </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->



                    <div class="article-navigation text-center">
                        <div class="row">
                            <div *ngIf="prevArticleWrapper" class="col-6 col-sm-6 col-md-6 col-lg-6">
                                <a href="{{prevArticleWrapper.article.surl}}?ua_src=navprev" class="navigation">
                                    <div [ngClass]="'article-page-step article-page-previous view fourth-effect'"
                                         [ngStyle]="{'background-image': 'url(' + environment.staticContentUrl + '/uploads/image/' + prevArticleWrapper.article.image.small+')'}">
                                    <div class="mask"></div>
                                    <div class="content">
                                        <div class="sign">Előző</div>
                                        <div class="category">[{{prevArticleWrapper.category.name}}]</div>
                                        <div class="title">{{prevArticleWrapper.article.title}}</div>
                                    </div>
                                    </div>
                                </a>
                            </div>


                            <div *ngIf="nextArticleWrapper" class="col-6 col-sm-6 col-md-6 col-lg-6">
                                <a href="{{nextArticleWrapper.article.surl}}?ua_src=navnext" class="navigation">
                                    <div [ngClass]="'article-page-step article-page-next view fourth-effect'"
                                         [ngStyle]="{'background-image': 'url(' + environment.staticContentUrl + '/uploads/image/' + nextArticleWrapper.article.image.small+')'}">
                                    <div class="mask"></div>
                                    <div class="content">
                                        <div class="sign">Következő</div>
                                        <div class="category">[{{nextArticleWrapper.category.name}}]</div>
                                        <div class="title">{{nextArticleWrapper.article.title}}</div>
                                    </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                <app-advertise [type]="'thin'"></app-advertise>
            </div>
        </div>
    </div>
    <app-sidebar></app-sidebar>
</div>
