import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BannerListResponse} from '../_response/banner-list-response';
import {EnvironmentModel} from "../_globals/environment-model";

@Injectable({
  providedIn: 'root'
})
export class BannerService {

    readonly GET_BANNERS_URL;

    constructor(private http: HttpClient, private environment: EnvironmentModel) {
        const backendUrl = environment.backendUrl;
        this.GET_BANNERS_URL = `${backendUrl}/banner/get`;
    }

    getBanners(): Observable<BannerListResponse> {
        return this.http.get<BannerListResponse>(this.GET_BANNERS_URL);
    }

}
