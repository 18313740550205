import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

    readonly altTitle: string

    constructor() {
        // this.altTitle = this.config.siteName + ' - ' + this.config.slogan;
    }

    ngOnInit() {
    }

}
