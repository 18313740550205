import { Component, OnInit } from '@angular/core';
import {CustomLogger} from "../../_globals/custom-logger";

@Component({
  selector: 'app-page-not-found404',
  templateUrl: './page-not-found404.component.html',
  styleUrls: ['./page-not-found404.component.css']
})
export class PageNotFound404Component implements OnInit {

  constructor(private logger: CustomLogger) {
  }

  ngOnInit() {
      this.logger.error("page not found, error 404")
  }

}
