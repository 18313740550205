import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    PLATFORM_ID
} from '@angular/core';
import {MenuItem} from '../../_entity/menu-item';
import {MenuService} from '../../_service/menu.service';
import {isPlatformBrowser} from "@angular/common";
import {CustomLogger} from "../../_globals/custom-logger";
import {makeStateKey, TransferState} from "@angular/platform-browser";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {

    public menu: MenuItem[];

    constructor(private backend: MenuService,
                private logger: CustomLogger,
                @Inject(PLATFORM_ID) private platformId: any,
                private readonly transferState: TransferState) {
    }

    ngOnInit() {
        this.menu = this.loadMenu();
    }

    loadMenu(): MenuItem[] {
        const envStoreKey = makeStateKey<string>('menu');
        if(isPlatformBrowser(this.platformId)) {
            return this.transferState.get(envStoreKey, <any> []);
        } else {
            this.backend.getMenu().subscribe(response => {
                this.transferState.set(envStoreKey, response.menu);
                return response.menu;
            });
        }
    }
}
